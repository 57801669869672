import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import Login from '../components/site/login'
import { RequireNoUser } from "../components/check_user"

export default () => (
  <RequireNoUser>
    <WebsiteLayout>
      <Login />
    </WebsiteLayout>
  </RequireNoUser>
);