import React, { useState } from "react"

// TODO support redirect based on query params

import Loader from "react-loader-spinner"

import { navigate, Link } from "../../services/routes" // "gatsby"
import { login_with_password } from "../../services/auth"

import getParams from "./params"
import Banner from "./banner"
import routes from "../../services/routes"

import smallform from "./smallform.module.css"
const css_login_fail = {
	fontSize: "16px",
	fontWeight: "bold",
	color: "red",
};

const LoginForm = () => {

	const params = getParams();

	const [state, setState] = useState({
		loading: false,
		formData: {
			email: '',
			password: '',
			stay_logged_in: '',
		},
		rejectReason: '',
	});
	const update = (event) => {
		if (event.target.name==="stay_logged_in") {
			event.target.value = event.target.checked ? "on" : "";
		}
		setState({
			...state,
			formData: {
				...state.formData,
				[event.target.name]: event.target.value,
			},
			rejectReason: '', // always reset when form changes
		});
	};
	const submit = async (event) => {
		event.preventDefault();
		setState({...state, loading: true});
		let result = await login_with_password(state.formData);
		if (result.success) {
			if ("board_id" in params) {
				navigate(routes.board(params.board_id));
			} else {
				navigate(routes.account.index);
			}
		} else {
			setState({
				...state,
				rejectReason: result.reason,
				loading: false,
			});
		}
	};

	return (
		<form className={smallform.smallform} id="login_form" action={routes.login} method="post">
			{("board_id" in params) &&
				<h4 style={{color:"red"}}>You need to log in to access this board.</h4>
			}
			<input
				className={`form-control ${smallform.formTextTop} ${smallform.formControl}`}
				type="text"
				placeholder="Email address"
				required={true}
				autoFocus={true}
				name="email"
				onChange={update}
			/>
			<input
				className={`form-control ${smallform.formTextBottom} ${smallform.formControl}`}
				type="password"
				placeholder="Password"
				required={true}
				name="password"
				onChange={update}
			/>
			<p style={{fontSize:"12px"}}>
				<Link to={routes.forgot_password}>Forgot Password?</Link>
			</p>
			<p>
				<input
					type="checkbox"
					name="stay_logged_in"
					onChange={update} 
				/>
				&nbsp;Keep me logged in
			</p>
			{(state.rejectReason.length > 0) &&
				<p style={css_login_fail}>
					{state.rejectReason}
				</p>
			}
			{ state.loading &&
				<Loader type="Rings" color={"rgb(59,172,240)"} height={50} />
			}
			{ !state.loading &&
				<button style={{border:"none"}} className="main_btn" type="submit" onClick={submit}>
					Log In
				</button>
			}
		</form>
	);
}

export default () => (
	<>
		<Banner title="Log In" link={routes.login} />
		<div>
			<section style={{textAlign:"center"}}>
				<div className="container">
					<p style={{marginTop:"20px"}}>
						Don't have an account yet? <Link to={routes.signup}>Sign up!</Link>
					</p>
					<div>
						<LoginForm />
					</div>
					{/*
					<p style={{marginTop:"20px"}}>
						or log in with <Link to={routes.auth_google}>Google</Link>
					</p>
					*/}
				</div>
			</section>
		</div>
	</>
);
